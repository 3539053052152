export class ConnectionError {
    error;
    constructor(error) {
        this.error = error;
    }
}
export class Notice {
    id;
    message;
    constructor(id, message) {
        this.id = id;
        this.message = message;
    }
}
export class Queue {
    waitingRoomNumber;
    currentTicket;
    admittedTicket;
    admittedTicketOnActivation;
    isPrequeue;
    admittedTicketOnLoad;
    constructor(waitingRoomNumber, currentTicket, admittedTicket, admittedTicketOnActivation, isPrequeue) {
        this.waitingRoomNumber = waitingRoomNumber;
        this.currentTicket = currentTicket;
        this.admittedTicket = admittedTicket;
        this.admittedTicketOnActivation = admittedTicketOnActivation;
        this.isPrequeue = isPrequeue;
        this.admittedTicketOnLoad = admittedTicket;
    }
    getPosition(ticketNumber) {
        return ticketNumber - this.admittedTicket;
    }
    getAdmittedTicketNormalized() {
        return this.normalize(this.admittedTicket);
    }
    normalize(n) {
        return n - this.admittedTicketOnActivation;
    }
    getQueuePosition(ticketNumber, positionOnLoad) {
        if (ticketNumber === null) {
            this.isPrequeue = true;
            return null;
        }
        this.isPrequeue = false;
        const position = this.getPosition(ticketNumber);
        const calculatedProgress = (position * 100) / positionOnLoad;
        const progress = this.normalize(ticketNumber) == 0
            ? 100
            : 100 - calculatedProgress;
        return new QueuePosition(ticketNumber, position, progress);
    }
}
export class QueuePosition {
    ticketNumber;
    position;
    progress;
    constructor(ticketNumber, position, progress) {
        this.ticketNumber = ticketNumber;
        this.position = position;
        this.progress = progress;
    }
}
export class State {
    ticketExpiryMinutes;
    admissionStartTime;
    constructor(ticketExpiryMinutes, admissionStartTime) {
        this.ticketExpiryMinutes = ticketExpiryMinutes;
        this.admissionStartTime = admissionStartTime;
    }
}
export class Ticket {
    id;
    number;
    prequeueNumber;
    constructor(id, number, prequeueNumber) {
        this.id = id;
        this.number = number;
        this.prequeueNumber = prequeueNumber;
    }
}
