import { HubConnectionBuilder } from "@microsoft/signalr";
import { ConnectionClosedEvent, StateUpdatedEvent, QueuePositionUpdatedEvent, NoticesUpdatedEvent } from "./Events";
import { Ticket, Queue, State, Notice, ConnectionError } from "./Models";
import { Helpers } from "./Helpers";
export class WaitingRoomConnection {
    ticket = new Ticket("", null, null);
    queue = new Queue(0, 0, 0, 0, false);
    state = new State(0, new Date(Date.now()));
    notices = new Array();
    positionOnLoad = 0;
    autoRefresh = false;
    connection;
    constructor() {
        this.connection = new HubConnectionBuilder()
            // .configureLogging(signalR.LogLevel.Trace)
            // with default value 0, two requests (page load and signalr reconnect) with the same ticket id cookie are being sent to the server
            // the ticket can either be expired or all tickets have been dropped
            // thus creating two separate tickets
            .withAutomaticReconnect([1000, 2000, 10000, 30000])
            .withUrl("/bouncer/hubs/waiting-room")
            .build();
        this.handleQueueUpdated();
        this.handleStateUpdated();
        this.handleNoticesUpdated();
        this.handlePrequeueCalculated();
        this.handleWaitingRoomUpdated();
        this.handleReloadWaitingClients();
        this.handleOnClose();
    }
    async startConnection() {
        try {
            // Start SignalR connection
            await this.connection.start();
            // Load initial data
            let data = await this.connection.invoke("getWaitingRoomData");
            console.log("signalr connection established and loaded initial data", data);
            // Only send validation if required
            const numbersUnset = data.ticket.number === null && data.ticket.prequeueNumber === null;
            if (!data.ticket.isValidated && numbersUnset) {
                let validation = await Helpers.sha256(data.ticket.id);
                const result = await fetch("bouncer/validation", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(validation)
                });
                if (result.ok) {
                    data = await this.connection.invoke("getWaitingRoomData");
                    console.log("validated sucessfully, reloading waitingroom data");
                }
                else {
                    const body = await result.text();
                    console.log(result.status, body);
                }
            }
            console.log("applying loaded data", data);
            this.autoRefresh = data.autoRefreshOnceThresholdIsMet;
            this.ticket = new Ticket(data.ticket.id, data.ticket.number, data.ticket.prequeueNumber);
            this.queue = new Queue(data.queue.waitingRoomNumber, data.queue.currentTicketNumber, data.queue.admittingTicketThreshold, data.queue.ticketNumberOnWaitingRoomActivation, data.queue.isPrequeue);
            this.state = new State(data.state.ticketExpiryMinutes, new Date(data.state.admissionStartTime));
            if (this.ticket.number !== null) {
                this.positionOnLoad = this.queue.getPosition(this.ticket.number);
            }
            this.notices = [];
            data.notices.forEach(notice => this.notices.push(notice));
            // Dispatch updates as events
            this.dispatchNoticesUpdatedEvent();
            this.dispatchStateUpdatedEvent();
            this.dispatchQueueUpdatedEvent();
        }
        catch (err) {
            console.error(err);
            Helpers.sleep(3000).then(() => {
                this.startConnection();
            });
        }
    }
    getQueuePosition() {
        return this.queue.getQueuePosition(this.ticket.number, this.positionOnLoad);
    }
    dispatchQueueUpdatedEvent() {
        const queuePosition = this.getQueuePosition();
        this.dispatchEvent(new QueuePositionUpdatedEvent(queuePosition));
        if (queuePosition !== null && queuePosition.position <= 0) {
            console.info("this ticket is admitted - stopping signalr connection ");
            this.connection.stop();
            if (this.autoRefresh) {
                console.info("auto refresh to shop");
                location.reload();
            }
        }
    }
    dispatchStateUpdatedEvent() {
        this.dispatchEvent(new StateUpdatedEvent(this.state));
    }
    dispatchNoticesUpdatedEvent() {
        this.dispatchEvent(new NoticesUpdatedEvent(this.notices));
    }
    dispatchEvent(event) {
        document.dispatchEvent(event);
    }
    handleReloadWaitingClients() {
        const reloadWaitingClientsKey = "reload_waiting_clients";
        this.connection.on(reloadWaitingClientsKey, (timeoutMilliseconds) => {
            const totalDelayMilliseconds = 1000 + Math.random() * timeoutMilliseconds;
            console.debug(`force reload from server has been requested. waiting for ${totalDelayMilliseconds}ms before refreshing`);
            Helpers.sleep(totalDelayMilliseconds).then(() => {
                console.debug(`force reloading page after waiting ${totalDelayMilliseconds}ms`);
                location.reload();
                return;
            });
        });
    }
    handleWaitingRoomUpdated() {
        const waitingRoomUpdatedKey = "waiting_room_updated";
        this.connection.on(waitingRoomUpdatedKey, async () => {
            // Stop SignalR connection
            this.connection.stop();
            // Waiting a random amount of time (1-11 seconds) to spread the load for the server
            await Helpers.sleep(1000 + Math.random() * 5000);
            // Connect to SignalR Hub and load current data after delay
            await this.startConnection();
        });
    }
    handlePrequeueCalculated() {
        const prequeueCalculatedKey = "prequeue_calculated";
        this.connection.on(prequeueCalculatedKey, async () => {
            console.log("prequeue is: ", this.ticket.prequeueNumber);
            if (this.ticket.prequeueNumber == null) {
                console.log("jetzt haben wir den salat weil wir den neuen warteraum verpasst haben");
                location.reload();
                return;
            }
            const newTicketNumber = await this.connection.invoke("getTicketNumberForPrequeueNumber", this.ticket.prequeueNumber);
            console.debug(`received ticket number ${newTicketNumber} for prequeue number ${this.ticket.prequeueNumber}`);
            this.ticket.number = newTicketNumber;
            this.queue.isPrequeue = false;
            // Load admitted ticket so the position and progress can be calucated correctly
            let queue = await this.connection.invoke("getQueue");
            this.queue.admittedTicket = queue.admittingTicketThreshold;
            this.positionOnLoad = this.queue.getPosition(this.ticket.number);
            this.dispatchQueueUpdatedEvent();
        });
    }
    handleNoticesUpdated() {
        const noticesUpdatedKey = "notices_updated";
        this.connection.on(noticesUpdatedKey, (updatedNotices) => {
            this.notices = [];
            updatedNotices.forEach(x => this.notices.push(new Notice(x.id, x.message)));
            this.dispatchNoticesUpdatedEvent();
        });
    }
    handleStateUpdated() {
        const stateUpdatedKey = "state_updated";
        this.connection.on(stateUpdatedKey, (state) => {
            this.state.ticketExpiryMinutes = state.ticketExpiryMinutes;
            this.state.admissionStartTime = new Date(state.admissionStartTime);
            this.dispatchStateUpdatedEvent();
        });
    }
    handleQueueUpdated() {
        const queueUpdatedKey = "queue_updated";
        this.connection.on(queueUpdatedKey, (admittingTicketThreshold) => {
            this.queue.admittedTicket = admittingTicketThreshold;
            this.dispatchQueueUpdatedEvent();
        });
    }
    handleOnClose() {
        this.connection.onclose((error) => {
            if (error === undefined) {
                console.debug("closed signalr connection without errors");
                this.dispatchEvent(new ConnectionClosedEvent(null));
                return;
            }
            console.warn(`disconnected from SignalR WaitingRoom Hub due to error "${error}".`);
            this.dispatchEvent(new ConnectionClosedEvent(new ConnectionError(error)));
        });
    }
}
