export const QUEUE_POSITION_UPDATED_KEY = "bouncer:queuePositionUpdated";
export const NOTICES_UPDATED_KEY = "bouncer:noticesUpdated";
export const STATE_UPDATED_KEY = "bouncer:stateUpdated";
export const CONNECTION_CLOSED_KEY = "bouncer:connectionClosed";
export class QueuePositionUpdatedEvent extends CustomEvent {
    constructor(data) {
        super(QUEUE_POSITION_UPDATED_KEY, {
            detail: data,
            bubbles: false,
            cancelable: false
        });
    }
}
export class NoticesUpdatedEvent extends CustomEvent {
    constructor(data) {
        super(NOTICES_UPDATED_KEY, {
            detail: data,
            bubbles: false,
            cancelable: false
        });
    }
}
export class StateUpdatedEvent extends CustomEvent {
    constructor(data) {
        super(STATE_UPDATED_KEY, {
            detail: data,
            bubbles: false,
            cancelable: false
        });
    }
}
export class ConnectionClosedEvent extends CustomEvent {
    constructor(data) {
        super(CONNECTION_CLOSED_KEY, {
            detail: data,
            bubbles: false,
            cancelable: false
        });
    }
}
